import { StrokePanel, Typography } from "./core/ui/typography/typography";
import { Button } from "./core/ui/button/button";

interface ICartProps {
  imageCart: string;
  nameTextCart: string;
  middleTextCart: string;
  mainTextCart: string;
  slides: string;
  slidesFile?: string;
}
export const Cart = (props: ICartProps) => {
  return (
    <div style={{ width: 370 }}>
      <div style={{ position: "absolute" }}>
        <img
          style={{
            position: "relative",
            width: 193,
            bottom: 75,
            left: 89,
          }}
          src={props.imageCart}
          alt="img"
        />
      </div>
      <img
        style={{ width: 370 }}
        src={`${process.env.PUBLIC_URL}/0.png`}
        alt="img"
      />
      <div
        style={{
          position: "absolute",
        }}
      >
        ``
        <div
          style={{ width: 300, position: "relative", bottom: 420, left: 37 }}
        >
          <Typography
            fontWeight={500}
            text={props.nameTextCart}
            fontSize={32}
            strokePanel={StrokePanel.Regular}
            color={"black"}
          />
          <Typography
            style={{ marginTop: 20 }}
            text={props.middleTextCart}
            fontSize={20}
            strokePanel={StrokePanel.Regular}
            color={"black"}
          />
          <Typography
            style={{ marginTop: 20 }}
            text={props.mainTextCart}
            fontSize={16}
            strokePanel={StrokePanel.Regular}
            color={"black"}
          />
          <div style={{ marginTop: 20, left: 47 }}>
            <Button
              onClick={() => window.open(`${process.env.PUBLIC_URL}/slides/${props.slidesFile}`, "_blank")}
              background={"#154E65"}
              width={300}
              height={44}
              text={props.slides}
            />
        </div>  
        </div>
      </div>
    </div>
  );
};
